<template>
    <el-container>
        <el-header height="64px">
            <div style="display: flex">
                <div style="height: 64px">
                    <img style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Index'})"
                         src="https://play-lh.googleusercontent.com/IvC6MQL_SsvE42lKqRW3NFQxNXib0zqQJH_60EwawKemrUJojDS5f_utap275iImfYI=s64-rw">
                </div>
                <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                    FindOut
                    <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                </div>
            </div>
            <div class="dark-switcher">
                <el-switch style="margin: 16px" v-model="dark" @change="changeDark"
                           active-action-icon="Moon" inactive-action-icon="Sunny"></el-switch>
            </div>
        </el-header>
        <el-container style="background-color: var(--el-border-color-extra-light)" :style="{height: height + 'px'}">
            <aside-nav ref="nav">
                <aside-group title="素材库">
                    <aside-item icon="Files" title="全局图库" index="Index"></aside-item>
                    <aside-item icon="Star" title="素材评分" index="Score"></aside-item>
                    <aside-item icon="Reading" title="分类管理" index="Category"></aside-item>
                    <aside-item icon="Aim" title="新手引导" index="Rookie"></aside-item>
                    <aside-item icon="Orange" title="轮播图" index="Carousel"></aside-item>
                    <aside-item icon="Timer" title="每日上新" index="Update"></aside-item>
                    <aside-item icon="Calendar" title="Daily" index="Daily"></aside-item>
                    <aside-item icon="Collection" title="集合" index="Collection"></aside-item>
                    <aside-item icon="CopyDocument" title="找不同" index="Difference"></aside-item>
                    <aside-item icon="Guide" title="关卡" index="Level"></aside-item>
                </aside-group>
            </aside-nav>
            <el-main style="padding: var(--el-main-padding)">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import {useDark, useToggle} from '@vueuse/core';
import AsideNav from "./base/AsideNav.vue";
import AsideGroup from "./base/AsideGroup.vue";
import AsideItem from "./base/AsideItem.vue";
import axios from "ts-axios-new";

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight - 64,
            media: window.matchMedia('(prefers-color-scheme: dark)'),
            country_list: [], cdn_url: import.meta.env.VITE_CDN_URL, authorization: '',
            image_module_enum: {1: 'Library', 2: 'Collection', 3: 'Daily'},
            image_status_enum: {0: '未设置', 1: '审核中', 2: '不通过', 3: '已通过'},
            image_type_enum: {1: '普通', 2: '线稿', 3: '标准', 4: '找猫'},
            image_style_enum: {1: '普通', 2: '加锁', 3: '轮廓'},
            user: {
                user_id: '',
                avatar: '',
                name: '',
                email: '',
                access: [],
            },
        }
    },
    methods: {
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        this.changeDark();
        axios.get('https://console.plutodesk.com/api/v1/country', {
            headers: {authorization: this.authorization}
        }).then(res => {
            this.country_list = res.data.data.countryList;
        });
    },
}
</script>
<style>
html, body {
    margin: 0;
    padding: 0;
}

.el-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}
</style>
<style scoped>
.dark-switcher ::v-deep(.el-switch__action) {
    color: #000 !important;
}

.dark-switcher ::v-deep(.el-switch.is-checked .el-switch__core) {
    background-color: #444;
    border-color: #444;
}
</style>